<template>
  <page-layout class="ma-24">
    <!-- 列表 -->
    <div class="bg-white">
      <p-table rowKey="id" :dataSource="dataSource" class="bg-white">
        <p-t-column field="card_no" min-width="200" title="油卡卡号" />
        <p-t-column field="card_type_desc" min-width="120" title="账户类型" />
        <p-t-column field="sp_name" min-width="120" title="服务商" />
        <p-t-column
          field="balance"
          min-width="120"
          title="余额"
          v-slot="{ row }"
          >{{ (row.balance / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column
          field="payment_amount"
          min-width="120"
          title="消费金额"
          v-slot="{ row }"
          >{{ (row.payment_amount / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column field="order_count" min-width="120" title="订单数" />
        <p-t-column field="status_desc" min-width="120" title="状态" />
        <p-t-column
          field="create_time"
          min-width="200"
          title="绑定时间"
          v-slot="{ row }"
        >
          <span>{{
            formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
          }}</span>
        </p-t-column>
        <p-t-column min-width="230" title="操作" v-slot="{ row }" fixed="right">
          <a-button
            v-if="$store.getters.roles.includes('TransferRecord')"
            type="link"
            style="padding-left:0;"
            @click="handleTransferRecords(row)"
            >划拨记录</a-button
          >
          <a-button
            v-if="$store.getters.roles.includes('ConsumerOrders')"
            type="link"
            style="padding-left:0;"
            @click="handleViewOrder(row)"
            >查看订单</a-button
          >
          <a-button
            v-if="$store.getters.roles.includes('DriverTransfer')"
            type="link"
            style="padding-left:0;"
            @click="showTransfer(row)"
            >划拨</a-button
          >
        </p-t-column> </p-table
      >划拨记录
    </div>
    <!-- 划拨弹窗 -->
    <a-modal
      v-model:visible="visible"
      title="划拨"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirmLoading="loading"
      :destroyOnClose="true"
    >
      <a-form
        ref="formRef"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :loading="true"
      >
        <a-form-item ref="sp_name" label="服务商" name="sp_name">
          {{ formState.sp_name }}
        </a-form-item>
        <a-form-item
          ref="refuel_card_type_name"
          label="账户类型"
          name="refuel_card_type_name"
        >
          {{ formState.refuel_card_type_name }}
        </a-form-item>
        <a-form-item ref="driver_name" label="收款司机姓名" name="driver_name">
          {{ formState.driver_name }}
        </a-form-item>
        <a-form-item
          ref="refuel_card_no"
          label="划入账户"
          name="refuel_card_no"
        >
          {{ formState.refuel_card_no }}
        </a-form-item>
        <a-form-item ref="amount" label="划拨金额" name="amount">
          <a-input
            v-model:value="formState.amount"
            placeholder="请输入划拨金额"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </page-layout>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { useGasCardDetailApi, useGasTransferApi } from "@/apis/oil";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "account-driver-detail",
  setup() {
    const router = useRouter();
    const route = useRoute();

    const tableDataSource = reactive(getInitTable());

    const gasCardDetail = useGasCardDetailApi();

    const getConsultingList = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          owner_type: "DRIVER",
          // ...route.query,
          driver_ids: [route.query.driver_id],
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await gasCardDetail(data);
        return { records: res.list, total: res.total };
      }
    });

    // 跳转到开票记录详情
    const handleActionClick = row => {
      console.log(row);
      let details = {
        id: row.id,
        name: row.apply_user,
        time: row.apply_time,
        total: row.amount
      };
      router.push({
        path: "/invoice/invoice-detail",
        query: details
      });
    };
    //划拨弹窗
    const visible = ref(false);
    const formRef = ref();
    const formState = ref({
      sp_name: "",
      sp_code: "",
      refuel_card_type_name: "",
      driver_name: "",
      driver_id: "",
      transfer_card_no: "",
      amount: "",
      refuel_card_type: ""
    });
    const wrapperCol = { span: 18 };
    const labelCol = { span: 6 };
    const showTransfer = row => {
      console.log("====>", row);
      formState.value.sp_name = row.sp_name;
      formState.value.sp_code = row.sp_code;
      formState.value.refuel_card_type = row.card_type;
      formState.value.refuel_card_type_name = row.card_type_desc;
      formState.value.driver_name = row.driver_name;
      formState.value.driver_id = route.query.driver_id;

      // formState.value.transfer_card_no = row.card_no;//划出油卡卡号
      formState.value.refuel_card_no = row.card_no; //划入油卡卡号
      visible.value = true;
      console.log("====>", formState);
    };
    const successMsg = msg => {
      message.success(msg || "提交成功！");
      formRef.value.resetFields();
      loading.value = false;
      visible.value = false;
    };

    const errorMsg = msg => {
      message.error(msg || "提交失败！");
      loading.value = false;
    };
    const useGasTransfer = useGasTransferApi();
    const loading = ref(false);
    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          loading.value = true;
          let res = await useGasTransfer({
            amount: formState.value.amount * 100,
            driver_id: formState.value.driver_id,
            refuel_card_no: formState.value.refuel_card_no,
            refuel_card_type: formState.value.refuel_card_type,
            sp_code: formState.value.sp_code
          });
          successMsg(res);
        })
        .catch(error => {
          console.log(error);
          errorMsg();
        });
    };
    const handleCancel = () => {
      formRef.value.resetFields();
    };
    //查看订单跳转
    const handleViewOrder = row => {
      console.log(row);
      let details = {
        driver_ids: route.query.driver_id,
        sp_code: row.sp_code
      };
      router.push({
        path: "/oilCard/consumer-orders",
        query: details
      });
    };
    //划拨记录跳转
    const handleTransferRecords = row => {
      console.log(row);
      let details = {
        driver_id: route.query.driver_id,
        sp_code: row.sp_code
      };
      router.push({
        path: "/oilCard/transfer-record",
        query: details
      });
    };
    return {
      loading,
      visible,
      formRef,
      wrapperCol,
      labelCol,
      formState,
      handleOk,
      handleCancel,
      showTransfer,
      getConsultingList,
      handleViewOrder,
      handleActionClick,
      handleTransferRecords,
      dataSource: tableDataSource,
      formatToDateTime
    };
  }
});
</script>
